.web-card {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    width: 304px;
    min-height: 500px;
    padding: 36px 32px 52px;
    border-radius: 12px;
}


input[type="date"]::-webkit-calendar-picker-indicator {
    opacity: 0;
    z-index: 1;
    cursor: pointer;
}

input[type="date"] {
    background-image: url('./assets/images/calendar.svg');
    background-position: calc(100% - 1.1rem) center !important;
    background-repeat: no-repeat;
    padding-right: 20px;
    cursor: pointer;
}


.common-input {
    padding: 12px;
    border: 1px solid var(--color-9E9E9E);
    border-radius: 8px;
    width: 100%;
    font-size: 15px;
    letter-spacing: .05px;
    color: var(--color-212121);
}

.common-input::placeholder {
    color: var(--color-9E9E9E);
}


.imp-text-424242 {
    color: var(--color-424242) !important;
}

.title-border-b{
    @apply pb-1 text-base font-medium tracking-[0.05px] text-[var(--color-212121)] w-full mt-9 border-b  border-[var(--color-E0E0E0)]
}

/* ######################## Number start ######################### */

.hide-number-arrow::-webkit-outer-spin-button,
.hide-number-arrow::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

.hide-number-arrow {
    -moz-appearance: textfield;
    /* Firefox */
}

/* ######################## Number end ######################### */
/* ######################## checkbox start ######################### */

input[type="checkbox"] {
    appearance: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    height: 20px;
    width: 20px;
    border-radius: 4px;
    border: 1.599px solid var(--color-0048D4);
    cursor: pointer;
    position: relative;
}

input[type="checkbox"]:checked::before {
    content: "";
    background: url("./assets/images/tik.svg") no-repeat center center;
    position: absolute;
    right: -0.8px;
    top: -0.8px;
    height: 20px;
    width: 20px;
    border-radius: 4px;
}

input[type="checkbox"]:disabled {
    position: relative;
}

input[type="checkbox"]:checked:disabled::before {
    content: "";
    background: url("./assets/images/greyTick.svg") no-repeat center center #c6c4c4 !important;
    position: absolute;
    right: -1px;
    top: -0.8px;
    height: 20px;
    width: 20px;
    border-radius: 4px;
    border: 1.5px solid var(--color-9E9E9E);
}

input[type="checkbox"]:disabled::before {
    content: "";
    background: #c6c4c4 !important;
    position: absolute;
    right: -1px;
    top: -0.8px;
    height: 20px;
    width: 20px;
    border-radius: 4px;
    border: 1.5px solid var(--color-9E9E9E);
}

/* ######################## checkbox end   ######################### */

/* ########################  start ######################### */
.custom-scrollBar {
    overflow-y: auto;
    overflow-x: hidden;
}

.custom-scrollBar::-webkit-scrollbar-track,
.group-dropdown::-webkit-scrollbar-track {
    background-color: rgb(253, 249, 249);
}

.custom-scrollBar::-webkit-scrollbar,
.group-dropdown::-webkit-scrollbar {
    width: 7px;
}

.custom-scrollBar::-webkit-scrollbar-thumb,
.group-dropdown::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background-color: #e6e8eb;
    border: 1px solid #e6e8eb;
}

/* ########################  end ######################### */
/* ######################## dropdown start ######################### */

.common-dropdown {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    cursor: pointer;
}

.common-dropdown:hover .extra-space,
.extra-space:hover .dropdown-list,
.common-dropdown:hover .dropdown-list {
    display: block !important;
}


.common-dropdown:hover .dropdown-list {
    display: block !important;
}

.extra-space {
    position: absolute;
    top: 15px;
    width: 100%;
    height: 100%;
    display: none;
}

.dropdown-list {
    z-index: 2;
    display: none;
    position: absolute;
    top: 31px;
    /* left: 0px; */
    background: var(--color-ffffff);
    padding: 24px 2px 24px 24px;
    border: 1px solid var(--color-ececec);
    box-shadow: 0px 2px 6px 2px rgba(0, 0, 0, 0.05);
    border-radius: 12px;
    width: 100%;
}

/* ######################## dropdown end ######################### */

.active-nav {
    border-radius: 8px;
    background: var(--color-0048d41a);
    padding: 10px 24px;
    color: var(--color-0048D4);
}

.allergic-item {
    display: flex;
    padding: 3px 10px;
    justify-content: center;
    align-items: center;
    border-radius: 18px;
    background: var(--color-EEEEEE);
    color: var(--color-212121);
    font-size: 14px;
}

.active-to {
    display: flex;
    padding: 6px 12px;
    align-items: center;
    border-radius: 4px;
    background: var(--color-EEE);
    color: var(--color-424242);
    font-size: 12px;
    font-weight: 500;
    letter-spacing: 0.05px;
}

.active-msg {
    border-bottom: 2px solid var(--color-0048D4);
    color: var(--color-0048D4) !important;
}

/* ######################## phone-input-liberary start ######################### */

.phone-input {
    width: 100% !important;
    height: auto !important;
    line-height: 24px !important;
    border-radius: 8px !important;
    border: 1px solid var(--color-9E9E9E) !important;
    padding: 12px 12px 12px 78px !important;
    letter-spacing: 0.05px !important;
}

.phone-input::placeholder {
    color: var(--color-9E9E9E) !important;
}

.phone-input-flag {
    border-radius: 8px 0px 0px 8px !important;
    border: 1px solid var(--color-9E9E9E) !important;
    border-right: none !important;
    background-color: var(--color-white) !important;
}

.phone-input-flag .selected-flag {
    border-radius: 8px 0px 0px 8px !important;
    width: 74px !important;
    height: 100% !important;
    padding: 0 0 0 16px !important;
}

.phone-input-flag .selected-flag .arrow {
    background: url("./assets/images/phoneArrow.svg") no-repeat !important;
    border: none !important;
    height: 24px !important;
    width: 57px !important;
    transform: translate(0%, -42%) !important;
    padding: 10px !important;
    left: -4px !important;
    z-index: 10;
}

/* ######################## phone-input-liberary end ######################### */

.dropdown-btn {
    font-size: 13px;
    font-weight: 400;
    letter-spacing: 0.05px;
    padding: 4px 11px;
    align-items: flex-start;
    border-radius: 4px;
    margin-right: 24px;
}

/* ######################## material ui pagination start ######################### */

.pagination li button {
    background-color: var(--color-FAFAFA) !important;
    color: var(--color-212B36) !important;
    font-weight: 500 !important;
    font-size: 14px !important;
    border: none !important;
    height: 32px !important;
    width: 32px !important;
}

.pagination li:first-child button {
    color: var(--color-757575) !important;
    background: var(--color-FAFAFA) !important;
    border: 1px solid var(--color-E0E0E0) !important;
}

.pagination li:last-child button {
    color: var(--color-424242) !important;
    background: var(--color-FAFAFA) !important;
    border: 1px solid var(--color-E0E0E0) !important;
}

.pagination .Mui-selected {
    border: none !important;
    background-color: var(--color-E5ECFA) !important;
    font-weight: 600;
    font-size: 14px;
    line-height: 16px;
    color: var(--color-0048D4) !important;
}

/* ######################## material ui pagination end ######################### */
/* ######################## react quill start ######################### */

.ql-container.ql-snow {
    border: 1px solid var(--color-EEE) !important;
}

.ql-toolbar.ql-snow {
    border: 1px solid var(--color-EEE) !important;
    border-bottom: none !important;
}

.validation-ql .ql-container.ql-snow {
    border: 1px solid var(--color-danger) !important;
}

.border-none .ql-container.ql-snow {
    border: none !important;
}

.validation-ql .ql-toolbar.ql-snow {
    border: 1px solid var(--color-danger) !important;
    border-bottom: none !important;
}

/* ######################## react quill end ######################### */
/* ######################## table start ######################### */

.table-role thead tr td {
    text-align: center;
    padding: 11px 6px;
    font-weight: 500;
}

.table-role tbody tr td:first-child {
    text-align: left;
}

.table-role thead tr td:first-child {
    text-align: left;
}

.table-role tbody tr td {
    text-align: center;
    padding: 6px;
}

/* ######################## table end ######################### */
/* ######################## custom select tag start ######################### */

.select-message {
    background: url("./assets/images/down_arrow.svg") no-repeat !important;
    background-position: calc(100% - 0.01rem) center !important;

}

.select-selected {
    background: url("./assets/images/selectArrow.svg") no-repeat;
    background-position: calc(100% - 0.75rem) center;
    -moz-appearance: none !important;
    -webkit-appearance: none !important;
    appearance: none !important;
    border: 1px solid var(--color-9E9E9E);
    border-radius: 8px;
}

.select-items div,
.select-selected {
    padding: 8px 16px;
    width: 100%;
    font-size: 15px;
    letter-spacing: .05px;
    cursor: pointer;
    user-select: none;
}

.select-items {
    position: absolute;
    top: 100%;
    left: 0;
    right: 0;
    z-index: 99;
    max-height: 200px;
    overflow-y: auto;
    background: var(--color-ffffff);
    padding: 16px 0px;
    border: 1px solid var(--color-ececec);
    box-shadow: 0px 2px 6px 2px rgba(0, 0, 0, 0.05);
    border-radius: 6px;

}

.select-items div:hover,
.same-as-selected {
    background-color: rgba(0, 0, 0, 0.1);
}

/* ######################## custom select tag end ######################### */
/* ######################## toast notification react-notifications-component start ######################### */

.rnc__notification-item {
    box-shadow: none !important;
    display: flex;
    justify-content: center;
    align-items: center;
}

.rnc__notification {
    width: 900px !important;
}

/* ######################## toast notification react-notifications-component end ######################### */
/* ######################## footer start ######################### */

.footer {
    @apply bg-transparent text-[var(--color-212121)] border-t border-[var(--color-e9e3e3)] text-[15px]  flex items-center justify-center w-full px-8 py-4
}

/* ######################## footer end ######################### */
/* ######################## auto-reply table start ######################### */

.autoreply-table-header {
    @apply border-b  bg-[var(--color-FAFAFA)] text-[13px] text-[var(--color-757575)] font-normal py-3 select-none;
}

.autoreply-table-cell {
    @apply border-b text-sm text-[var(--color-2121210)] py-[18px] font-normal align-middle ;
}

  /* ######################## auto-reply table end ######################### */